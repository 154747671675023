import { Navigate } from "react-router-dom";
import React from "react";

import { DataContext } from "../context/Data";
import { AstratoProvider } from "../context/Astrato";
import PortalRoutesV2 from "../routes/PortalRoutesV2";
import HeaderV2 from "../components/Header/HeaderV2";
import AdmRoutes from "../routes/AdmRoutes";
import SSO from "../components/Auth/SSO";
import { Route, Routes } from "react-router-dom";

const Portal = () => {
  const { space } = React.useContext(DataContext);

  const getPortal = () => {
    switch (space) {
      case "dtc":
        return <Navigate to="/v2/dtc" />;

      case "haasbros":
      case "depl":
        return <Navigate to="/v2/depl" />;

      case "v2/dtc":
      case "v2/depl":
        return (
          <AstratoProvider>
            <HeaderV2 space={space} />
            <PortalRoutesV2 />
          </AstratoProvider>
        );

      case "admin":
        return (
          <div>
            <HeaderV2 />
            <AdmRoutes />
          </div>
        );

      default:
        return (
          <Routes>
            <Route path="/sso/jwt/login" element={<SSO />} />
          </Routes>
        );
    }
  };

  return (
    <>
      <div style={{ height: "100%", backgroundColor: "#fafbfd" }}>{getPortal()}</div>
    </>
  );
};
export default Portal;
